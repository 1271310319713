
import utils from '@/utils/utils'
import Student from '@/types/student'
import { defineComponent } from 'vue'
const emptyStudent: Student = {
  id: 0,
  createdAt: '',
  updatedAt: '',
  accountId: 0,
  firstName: '',
  lastName: '',
  birthdate: '',
  grade: 0,
  notes: ''
}

export default defineComponent({
  data () {
    return {
      student: emptyStudent
    }
  },
  async mounted () {
    const id = this.$route.params.id
    if (!Array.isArray(id) && id !== 'new') {
      const response = await this.$api.getStudent(parseInt(id))
      if (response.student) {
        this.student = response.student
      }
    }
  },
  methods: {
    birthdateFormat () {
      const dateInputValue = utils.dateStringToDateInputValue(this.student.birthdate)
      return dateInputValue
    },
    setBirthdate (event: Event) {
      const target = event.target
      if (target instanceof HTMLInputElement) {
        const dateString = utils.dateInputValueToDateString(target.value)
        this.student.birthdate = dateString || this.student.birthdate
      }
    },
    async saveStudent () {
      if (this.student.id === 0) {
        const response = await this.$api.createStudent(this.student)
        if (response.student) {
          this.$router.replace({ name: 'student_list' })
        }
      } else {
        const response = await this.$api.updateStudent(this.student)
        if (response.student) {
          this.$router.replace({ name: 'student_list' })
        }
      }
    },
    async deleteStudent () {
      if (!confirm('Are you sure you want to delete this student?')) {
        return
      }
      if (this.student.id === 0) {
        this.$router.replace({ name: 'student_list' })
      } else {
        const response = await this.$api.deleteStudent(this.student.id)
        if (response.success) {
          this.$router.replace({ name: 'student_list' })
        }
      }
    }
  }
})
